import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { withPreview } from 'gatsby-source-prismic';
import styled from 'styled-components';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';
import HeroText from '../components/text/hero-text';
import ThreeTwoImage from '../components/images/three-two-image';

const HeroImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;

    object-fit: cover;
    max-height: 560px;
  }
`;

const Card = styled.div`
  margin-bottom: 100px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3,
  span {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & p {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3 {
    font-size: 18px;
  }

  & .published {
    margin: 5px 0 10px 0;
    font-size: 12px;
    line-height: 12px;
    color: rgba(51, 51, 51, 0.4);

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & a > span {
    font-size: 13px;
    line-height: 100%;

    letter-spacing: 0.04em;

    color: rgba(35, 35, 35, 0.5);

    &:hover {
      color: #934e2a;
    }
  }

  & p {
    color: #323232;
    margin: 5px 0 15px 0;
  }
`;

const Contract = ({ data }) => {
  const content = data.prismicContract.data.projects
    .filter(content => content.project.document !== null)
    .map((content, index) => (
      <Col col={12} md={6} lg={4} key={content.project.document.id}>
        <Card>
          <Link to={'/contract/' + content.project.document.uid}>
            {content.project.document.data.image.fluid !== null && (
              <ThreeTwoImage
                image={content.project.document.data.image.url}
                alt={content.project.document.data.image.alt}
                srcWebp={content.project.document.data.image.fluid.srcWebp}
                srcSetWebp={
                  content.project.document.data.image.fluid.srcSetWebp
                }
              />
            )}
            <h3>{content.project.document.data.title.text}</h3>
            <p>{content.project.document.data.excerpt}</p>
          </Link>
        </Card>
      </Col>
    ));

  return (
    <>
      <Helmet
        title={`Contract – Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `${data.prismicContract.data.text.text}`,
          },

          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Contract – Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `${data.prismicContract.data.text.text}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
        ]}
      />

      <HeroImage>
        {data.prismicContract.data.image.fluid !== null && (
          <img
            src={data.prismicContract.data.image.fluid.srcWebp}
            srcSet={data.prismicContract.data.image.fluid.srcSetWebp}
            alt={data.prismicContract.data.image.alt}
          />
        )}
      </HeroImage>

      <HeroText
        text={data.prismicContract.data.text}
        padding={'70px 0'}
        mobilePadding={'30px 0'}
      />

      <Container marginTop={'0'}>
        <Row>{content}</Row>
      </Container>
    </>
  );
};

export default withPreview(Contract);

export const query = graphql`
  query Contract {
    prismicContract {
      data {
        text {
          html
          text
        }
        image {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
                url
                data {
                  excerpt
                  image {
                    alt
                    fluid(maxWidth: 800) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
